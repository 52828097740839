
import { Options, Vue } from "vue-class-component";
import { ChatIcon } from "@heroicons/vue/solid";
import { Prop } from "vue-property-decorator";
import { PostForm, PostType } from "../types/question/question-type";

@Options({
  components: { ChatIcon },
})
export default class FinalAnswerInput extends Vue {
  @Prop() readonly _id!: number;
  @Prop() readonly answer!: number;

  post: PostType = PostForm;
}
