
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { UserCommentType } from "../types/question/question-type";
import { HeartIcon, ChatIcon } from "@heroicons/vue/solid";
import QuestionComment from "../components/QuestionComment.vue";
import store from "../store";
import QuestionAnswerInput from "./QuestionAnswerInput.vue";

@Options({
  components: { QuestionAnswerInput, QuestionComment, HeartIcon, ChatIcon },
})
export default class QuestionAnswerForm extends Vue {
  @Prop() readonly answer!: UserCommentType;
  @Prop() readonly solved!: boolean;
  isLoggedIn = (): boolean => store.getters.isLoggedIn;
  is_correct = false;
  bonus_points = 0;
}
