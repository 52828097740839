
import { Options, Vue } from "vue-class-component";
import { QuestionDetailType, QuestionDetailDefault } from "../types/question/question-type";
import { FinalAnswerType, AnswerAwardType, FinalAnswerDefault } from "../types/question/question-type";
import { HeartIcon, ArrowCircleDownIcon } from "@heroicons/vue/solid";
import QuestionAnswerForm from "../components/QuestionAnswerForm.vue";
import FinalAnswerInput from "../components/FinalAnswerInput.vue";
import store from "../store";
import { questionAnswerHandler, questionDetailHandler } from "../handlers/question/question-handlers";
import Category from "../components/Category.vue";
import { ChatIcon } from "@heroicons/vue/solid";
import { AlertSeverities, showAlert } from "@/helpers/alerts";

@Options({
  components: { ChatIcon, QuestionAnswerForm, FinalAnswerInput, Category, HeartIcon, ArrowCircleDownIcon },
})
export default class FinalAnswer extends Vue {
  isLoggedIn = (): boolean => store.getters.isLoggedIn;

  private input = "";

  private question_display: QuestionDetailType = QuestionDetailDefault;

  private final_payload: FinalAnswerType = FinalAnswerDefault;

  async submitFinalAnswer(): Promise<void> {
    const payload = this.final_payload;
    const length = this.question_display.answers.length;
    for (let i = 0; i < length; i++) {
      const element = this.$refs[`questionAnswerForm-${i}`] as HTMLFormElement;
      if (element.is_correct) {
        const award: AnswerAwardType = {
          id_answer: element.answer._id,
          bonus_points: element.bonus_points,
        };
        payload.award.push(award);
      }
    }
    payload.post = this.input;

    const response = await questionAnswerHandler(payload, parseInt(this.$route.params.id.toString(), 10));
    if (response === undefined) return;
    showAlert("Úspěch", "Finální odpověď úspěšně přidána.", AlertSeverities.success);
    this.$router.go(-1);
  }

  mounted(): void {
    questionDetailHandler(parseInt(this.$route.params.id.toString(), 10))
      .then((questionsDetailRes: QuestionDetailType) => {
        this.question_display = questionsDetailRes;
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
